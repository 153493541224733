import { useMemo, useState } from 'react';
import { CtaButton } from '@/components/Cta/Cta';
import { useCustomerContext } from '@/lib/customer/CustomerContext';
import { isValidEmail as _isValidEmail } from '@/lib/isValidEmail';
import { AUTH_STEPS } from '../../constants';
import { StepProps } from '../types';

const EmailStep = ({ setStep, data, setData }: StepProps) => {
  const { cognitoLogin, loading } = useCustomerContext();
  const [error, setError] = useState('');

  const isValidEmail = useMemo(() => _isValidEmail(data.email), [data.email]);

  const onContinueClick = async () => {
    try {
      const auth = await cognitoLogin(data.email);

      if (!auth?.error) setStep(AUTH_STEPS.OTP_VERIFICATION);
      else
        setError(auth.message ?? 'Something went wrong while sending the OTP.');
    } catch (e) {
      setError('Something went wrong while sending the OTP.');
    }
  };

  return (
    <div className="mt-2.5">
      <input
        className="h-12 w-full rounded border border-gray-700/10 px-3 text-gray-700 outline-1 outline-gray-700 placeholder:text-gray-700/50"
        placeholder="Enter your email..."
        type="email"
        value={data.email}
        onChange={(e) => {
          if (error) setError('');
          setData({ ...data, email: e.target.value });
        }}
      />

      <CtaButton
        type="submit"
        className="mt-2 !h-12 w-full !rounded !text-sm"
        onClick={onContinueClick}
        loading={loading}
        disabled={!isValidEmail}
      >
        Continue
      </CtaButton>
      {error ? <div className="text-sm text-red">{error}</div> : null}
    </div>
  );
};

export default EmailStep;

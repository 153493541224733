import React, { useState } from 'react';
import Image from 'next/future/image';
import { CtaButton } from '@/components/Cta/Cta';
import { useCustomerContext } from '@/lib/customer/CustomerContext';
import { AUTH_STEPS } from '../../constants';
import { CognitoPasswordStepProps } from '../types';
import openEyeIcon from '@/public/icons/account/eye_open.svg';
import closedEyeIcon from '@/public/icons/account/eye_closed.svg';

const PasswordStep = ({
  setStep,
  data,
  setData,
  onSuccess,
}: CognitoPasswordStepProps) => {
  const { cognitoLogin, loading } = useCustomerContext();
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const loginAndCallback = async () => {
    try {
      const auth = await cognitoLogin(data.email, data.password);

      if (!auth?.error) onSuccess();
      else setError(auth?.message ?? 'Incorrect password. Please try again.');
    } catch (err) {
      setError('Incorrect password. Please try again.');
    }
  };

  return (
    <React.Fragment>
      <div className="text-sm">
        {data.email}{' '}
        <button
          onClick={() => setStep(AUTH_STEPS.EMAIL)}
          className="underline underline-offset-1"
        >
          Change
        </button>
      </div>
      <div className="">
        <div className="relative">
          <input
            className="mt-2 h-12 w-full rounded border border-gray-700/10 px-3 text-gray-700 outline-1 outline-gray-700 placeholder:text-gray-700/50"
            placeholder="Password"
            type={showPassword ? 'text' : 'password'}
            value={data.password}
            onChange={(e) => setData({ ...data, password: e.target.value })}
          />
          <button
            className="absolute right-3 top-1/2 mt-1 h-5 w-5 -translate-y-1/2"
            onClick={() => setShowPassword((prev) => !prev)}
          >
            <Image
              src={showPassword ? openEyeIcon : closedEyeIcon}
              alt="Toggle Password"
              height={50}
              width={50}
              className="h-full w-full"
            />
          </button>
        </div>
        <CtaButton
          type="button"
          className="mt-2.5 !h-12 w-full !rounded !text-sm"
          onClick={() => loginAndCallback()}
          loading={loading}
        >
          Sign in
        </CtaButton>
        {error ? <div className="text-sm text-red">{error}</div> : null}
      </div>
    </React.Fragment>
  );
};

export default PasswordStep;

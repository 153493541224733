/* eslint-disable react/forbid-dom-props */
import React, { useEffect, useState } from 'react';
import Image from 'next/future/image';
import { useRouter } from 'next/router';
import { loadGoogleSigninScript } from '@/lib/google-signin';
import { AUTH_STEPS } from '../constants';
import { CustomerFormType } from '../types';
import Or from '../../Or';
import EmailStep from './Steps/Email';
import OtpVerificationStep from './Steps/OtpVerification';
import { CognitoLoginFormProps, LoginDataT } from './types';
import PasswordStep from './Steps/Password';
import googleIcon from '@/public/icons/account/google.svg';
import ForgotPasswordStep from './Steps/ForgotPassword';
import ResetPasswordStep from './Steps/ResetPassword';

export default function CognitoLoginForm(props: CognitoLoginFormProps) {
  const router = useRouter();
  const [step, setStep] = useState(AUTH_STEPS.EMAIL);
  const [data, setData] = useState<LoginDataT>({
    email: '',
    password: '',
    otp: '',
    newPassword: '',
  });

  useEffect(() => {
    loadGoogleSigninScript();
  }, []);

  const generateHeader = () => {
    if (step === AUTH_STEPS.OTP_VERIFICATION) return 'Verification Code';
    if (step === AUTH_STEPS.PASSWORD) return 'Sign in with Password';
    if (step === AUTH_STEPS.FORGOT_PASSWORD) return 'Recover Password';
    if (step === AUTH_STEPS.RESET_PASSWORD) return 'Verification Code';

    return 'Sign in';
  };

  const signInWithGoogle = () => {
    window.location.href = '/api/customer/auth/cognito/google';
  };

  const header = generateHeader();

  return (
    <div className="text-sm">
      <div className="flex items-center justify-between">
        <h2 className="text-xl font-bold">{header}</h2>
        {step === AUTH_STEPS.PASSWORD && (
          <button
            onClick={() => setStep(AUTH_STEPS.FORGOT_PASSWORD)}
            className="text-[#0D6EFD] underline-offset-1 hover:underline"
          >
            Reset Password
          </button>
        )}
      </div>
      <div>
        {/* Email Step */}
        {step === AUTH_STEPS.EMAIL && (
          <EmailStep data={data} setData={setData} setStep={setStep} />
        )}
        {/* OTP Verification Step */}
        {step === AUTH_STEPS.OTP_VERIFICATION && (
          <OtpVerificationStep
            data={data}
            setData={setData}
            setStep={setStep}
            onSuccess={props.onSuccess}
          />
        )}
        {/* Password Step */}
        {step === AUTH_STEPS.PASSWORD && (
          <PasswordStep
            data={data}
            setData={setData}
            setStep={setStep}
            onSuccess={props.onSuccess}
          />
        )}
        {/* Password Recovery */}
        {step === AUTH_STEPS.FORGOT_PASSWORD && (
          <ForgotPasswordStep data={data} setData={setData} setStep={setStep} />
        )}
        {/* Password Recovery */}
        {step === AUTH_STEPS.RESET_PASSWORD && (
          <ResetPasswordStep data={data} setData={setData} setStep={setStep} />
        )}
        {![AUTH_STEPS.FORGOT_PASSWORD, AUTH_STEPS.RESET_PASSWORD].includes(
          step,
        ) ? (
          <React.Fragment>
            <Or className="my-2" />
            {step === AUTH_STEPS.OTP_VERIFICATION && (
              <button
                type="button"
                className="h-12 w-full rounded border border-gray-700 text-sm font-bold underline-offset-1 hover:underline"
                onClick={() => setStep(AUTH_STEPS.PASSWORD)}
              >
                Sign in with password
              </button>
            )}
            {step === AUTH_STEPS.PASSWORD && (
              <button
                type="button"
                className="h-12 w-full rounded border border-gray-700 text-sm font-bold underline-offset-1 hover:underline"
                onClick={() => setStep(AUTH_STEPS.OTP_VERIFICATION)}
              >
                Sign in with a passkey
              </button>
            )}
            <button
              type="button"
              className="relative mt-2 flex h-12 w-full items-center justify-center gap-x-2 rounded border border-gray-700 px-5 text-sm font-bold underline-offset-1 hover:underline"
              onClick={() => signInWithGoogle()}
            >
              <div className="h-5 w-5">
                <Image
                  alt="Sign in with Google"
                  src={googleIcon}
                  height={50}
                  width={50}
                  className="h-full w-full"
                />
              </div>
              <span className="block">Sign in with Google</span>
            </button>
            <div className="my-4 h-px w-full flex-1 bg-gray-700/10" />
            <div className=" text-center">
              Dont have an account?{' '}
              <button
                className="text-[#0D6EFD] underline underline-offset-1"
                onClick={() => {
                  if (props.setFormType)
                    props.setFormType(CustomerFormType.REGISTER);
                  else router.push('/account/register');
                }}
              >
                Sign up
              </button>
            </div>
          </React.Fragment>
        ) : (
          <div className="text-center">
            <div className="my-4 h-px w-full flex-1 bg-gray-700/10" />
            <div>
              Remembered your password?{' '}
              <button
                type="button"
                onClick={() => setStep(AUTH_STEPS.PASSWORD)}
                className="text-[#0D6EFD] underline underline-offset-1"
              >
                Back to login
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

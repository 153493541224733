import cn from 'classnames';

const Or = ({ className }: { className: string }) => (
  <div className={cn('flex w-full items-center', className)}>
    <div className="h-px flex-1 bg-gray-700/10" />
    <div className="mx-2 text-sm font-bold text-gray-700">OR</div>
    <div className="h-px flex-1 bg-gray-700/10" />
  </div>
);

export default Or;

import React, { useState } from 'react';
import { CtaButton } from '@/components/Cta/Cta';
import { useCustomerContext } from '@/lib/customer/CustomerContext';
import { AUTH_STEPS, OTP_LENGTH } from '../../constants';
import { OtpVerificationStepProps } from '../types';

const OtpVerificationStep = ({
  setStep,
  data,
  setData,
  onSuccess,
}: OtpVerificationStepProps) => {
  const { cognitoVerifyOtp, cognitoResendOtp, loading } = useCustomerContext();
  const [error, setError] = useState('');

  const onSignInClick = async () => {
    try {
      const auth = await cognitoVerifyOtp(data.email, data.otp);

      if (!auth?.error) onSuccess();
      else
        setError(auth?.message ?? 'Invalid or expired OTP. Request a new one.');
    } catch (e) {
      setError('Something went wrong while verifying the OTP.');
    }
  };

  const onResendClick = async () => {
    try {
      const customer = await cognitoResendOtp(data.email);

      if (!customer.error) {
        setStep(AUTH_STEPS.OTP_VERIFICATION);
      } else {
        setError(
          customer?.message ?? 'Something went wrong while resending the OTP.',
        );
      }
    } catch (e) {
      setError('Something went wrong while resending the OTP.');
    }
  };

  return (
    <React.Fragment>
      <div className="flex flex-wrap items-center justify-between gap-x-2">
        <div>
          Sent to {data.email}{' '}
          <button
            onClick={() => setStep(AUTH_STEPS.EMAIL)}
            className="underline underline-offset-1"
          >
            Change
          </button>
        </div>
        <button
          className="text-[#0D6EFD] underline-offset-1 hover:underline"
          onClick={onResendClick}
        >
          Resend
        </button>
      </div>
      <input
        type="text"
        placeholder="12345678"
        className="mt-2.5 h-12 w-full rounded border border-gray-700/10 px-3 text-gray-700 outline-1 outline-gray-700 placeholder:text-gray-700/50"
        value={data.otp}
        onChange={(e) => setData({ ...data, otp: e.target.value })}
        autoComplete="otp"
      />
      <CtaButton
        type="button"
        className="mt-2.5 !h-12 w-full !rounded !text-sm"
        onClick={onSignInClick}
        loading={loading}
        disabled={data.otp.length !== OTP_LENGTH}
      >
        Sign in
      </CtaButton>
      {error ? <div className="text-sm text-red">{error}</div> : null}
    </React.Fragment>
  );
};

export default OtpVerificationStep;
